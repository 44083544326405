import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'
import Meta from 'vue-meta'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    meta: {
      name: '',
      requiresAuth: true
    },
    redirect: {
      path: '/login'
    }
  },
  {
    path: '/login',
    meta: {
      name: 'Login',
      requiresAuth: false
    },
    component: () =>
      import(/* webpackChunkName: "Login" */ '../views/LoginHome.vue'),
      // redirect if already signed in
      beforeEnter: (to, from, next) => {
        if (store.getters.authorized) {
          next('/Home')
        } else {
          next()
        }
      },
    children: [{
      path: '',
      component: () => import(/* webpackChunkName: "LoginForm" */ '../components/LoginForm.vue')
    }]
  },
  {
    path: '/home',
    meta: {
      name: 'Home',
      requiresAuth: true,
    },
    component: Home,
    children: [
      {
          path: '',
          name: 'Principal',
          component: () => import('../views//Home.vue')
      },
      {
        path: 'matriculados',
        meta: {
            name: 'Consulta de Matriculados',
            requiresAuth: true
        },
        component: () => import('../views/matriculados/Matriculados.vue')
      },
      {
        path: 'repmatriculados',
        meta: {
          name: 'Listados de Matriculados',
          requiresAuth: true
        },
        component: () => import('../views/matriculados/ListadosMatriculados.vue')
      },
      {
        path: 'ficheromatriculados',
        meta: {
          name: 'Fichero Matriculados',
          requiresAuth: true
        },
        component: () => import('../views/matriculados/AdminMatriculados.vue')
      }
    ]
  },
  {
    path: '/matriculado/:id',
    meta: {
      name: 'Matriculado',
      requiresAuth: false,
    },
    component: () => import(/* webpackChunkName: "matriculado" */ '../views/publico/Matriculado.vue')
  }
]

const router = new VueRouter({
  base: '',
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.authorized) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

Vue.use(Meta)

export default router
